<template>
  <Dialog
    v-model:visible="isOpen"
    :closable="false"
    :closeOnEscape="true"
    :style="{ width: '50vw' }"
  >
    <template #header>
      <h3>คะแนนสมรรถภาพสมอง (TMSE)</h3>
    </template>
    <div class="p-field p-col-12 p-sm-12">
      การประเมินสมรรถภาพสมอง (Thai Mental State Examination: TMSE) วงกลมรอบคำตอบที่ ผู้สูงอายุทำได้ (ช่องคำตอบละ 1 คะแนน: ตอบถูกได้ 1 คะแนน/ ตอบผิดได้ 0 คะแนน)
    </div>
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight"
        >1) วันนี้วันอะไรของสัปดาห์
        <b>(จันทร์ อังคาร พุธ พฤหัสบดี ศุกร์ เสาร์ อาทิตย์)</b>
      </label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(2)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice1']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight">2) วันนี้วันที่เท่าไร</label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(2)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice2']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight">3) เดือนนี้เดือนอะไร</label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(2)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice3']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >4) ขณะนี้เป็นช่วง (ตอน) ไหนของวัน
        <b>(เช้า สาย เที่ยง บ่าย เย็น มืดค่ำ)</b></label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(2)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice4']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >5) ที่นี่ที่ไหน (บริเวณที่เก็บแบบสำรวจ เช่น บ้าน)</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(2)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice5']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight">6) คนที่เห็นในภาพนี้มีอาชีพอะไร </label>
      <div><img :src="assetsNurse" alt="nurse" class="img-responsive" /></div>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(2)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice6']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >7) ผู้สำรวจบอกชื่อของ 3 อย่าง โดยพูดเว้นช่วงครั้งละ 1 วินาที
        <b>(ต้นไม้ รถยนต์ มือ)</b> เพียงครั้งเดียว แล้วจึงให้ผู้สูงอายุพูดตาม
        หากพูดได้ครบในครั้งแรก ได้ 1 คะแนนในคำตอบที่ถูกต้อง ตอบสลับได้
        ตอบช้าได้ตามเห็นสมควร</label
      >
      <label for="wight"
        >*
        <b><u>หมายเหตุ</u></b> หลังจากให้คะแนนแล้วให้บอกซ้ำจนผู้สูงอายุจำได้ทั้ง
        3 อย่าง และบอกให้ผู้สูงอายุทราบว่าสักครู่จะกลับมาถามใหม่อีกครั้ง
      </label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(4)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice7']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >8) ให้บอกวันอาทิตย์-วันจันทร์ย้อนหลัง ให้ครบสัปดาห์ (ตอบได้ไม่เกิน 2
        ครั้ง) การให้คะแนนคำตอบ วันละ 1 คะแนน คือ วันอาทิตย์
        <b>วันเสาร์ วันศุกร์ วันพฤหัสบดี วันพุธ วันอังคาร</b> วันจันทร์</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(6)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice8']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >9) ให้คำนวณตัวเลขแล้วบอกคำตอบ
        <b>100-7 ไปเรื่อย ๆ 3 ครั้ง</b> (ตอบถูกได้ 1 คะแนน)
        ใช้เวลาคิดในแต่ละช่วงคำตอบไม่เกิน 1 นาที ถ้าผู้สูงอายุไม่ตอบ คำถามที่ 1
        ให้ตั้งเลข <b>93-7</b> ในการคำนวณครั้งต่อไป และ <b>86-7</b> ในครั้ง
        สุดท้ายตามลำดับ(<b>คำตอบ คือ 93, 86 และ 79 ตามลำดับ</b>)</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(4)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice9']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >10)
        ผู้สำรวจชี้ไปที่<b>สิ่งของ/วัสดุ/อุปกรณ์/สิ่งมีชีวิต</b>ในบริเวณนั้น
        แล้วถาม ผู้สูงอายุว่า “<b>โดยทั่วไปเราเรียก สิ่งนี้ว่าอะไร?</b>” เช่น
        ชี้ต้นไม้ ตอบต้นไม้</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(2)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice10']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >11) ผู้สำรวจชี้ไปที่เสื้อของตนเอง แล้วถามผู้สูงอายุว่า “<b
          >โดยทั่วไปเราเรียกสิ่งนี้ ว่าอะไร?</b
        >”</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(2)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice11']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >12) ผู้สำรวจบอกผู้สูงอายุว่า จงฟังประโยคต่อไปนี้ให้ดี
        แล้วจำไว้จากนั้นให้พูดตาม “<b>ยายพาหลานไปซื้อขนมที่ตลาด</b>”</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(2)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice12']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >13) จงทำตามคำสั่งต่อไปนี้ (มี 3 ขั้นตอนคำสั่ง)
        ให้ผู้สำรวจ<b>พูด</b>ต่อกันไปให้ครบ ทั้ง 3 ขั้นตอน ให้คะแนนขั้นตอนละ 1
        คะแนน</label
      >
      <div class="p-field p-col-12 p-sm-8">
        <label for="wight">“<b>หยิบกระดาษด้วยมือขวา</b>”</label>
      </div>
      <div class="p-field p-col-12 p-sm-8">
        <label for="wight">“<b>พับกระดาษเป็นครึ่งแผ่น</b>”</label>
      </div>
      <div class="p-field p-col-12 p-sm-8">
        <label for="wight">“<b>แล้วส่งกระดาษให้ผู้ตรวจ</b>”</label>
      </div>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(4)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice13']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >14) ให้ผู้สูงอายุ<b><u>อ่านแล้วทำตาม</u></b></label
      >
      <div class="p-field p-col-12 p-sm-8">
        <label for="wight">“<b>หลับตา</b>”</label>
      </div>

      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(2)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice14']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >15)
        จงวาดภาพต่อไปนี้ให้เหมือนตัวอย่างมากที่สุดเท่าที่ท่านจะสามารถทำได้</label
      >
      <div><img :src="assetsHome" alt="pic" class="img-responsive" /></div>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(3)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice15']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>
      <label for="wight"><b>พิจารณาการให้คะแนน</b></label
      ><br />
      <label for="wight"
        >พิจารณามุมครบ (3 เหลี่ยม + 4 เหลี่ยม = 7 มุม)
        และมีด้านหนึ่งซ้อนทับกัน</label
      ><br />
      <label for="wight"
        >- วาดภาพสามเหลี่ยมและสี่เหลี่ยมเชื่อมต่อกัน<u
          >เหมือนภาพตัวอย่าง ได้คะแนน 1,1</u
        ></label
      ><br />
      <label for="wight"
        >- วาดภาพสามเหลี่ยมและสี่เหลี่ยมเชื่อมต่อกัน<u
          >คล้ายภาพตัวอย่างแต่ไม่สมบูรณ์ ได้คะแนน 1,0</u
        ></label
      ><br />
      <label for="wight"
        >- วาดภาพ<u>ไม่ใกล้เคียงภาพตัวอย่าง ได้คะแนน 0,0</u></label
      ><br />

      <label for="wight">16) กล้วยกับส้มเหมือนกัน คือ เป็นผลไม้</label>
      <div class="p-field p-col-12 p-sm-8">
        <label for="wight"><b>แมวกับสุนัขเหมือนกัน</b> คือ เป็นอะไร?</label>
      </div>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(2)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice16']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >17) สิ่งของ 3 อย่างที่บอกให้จำเมื่อสักครู่ มีอะไรบ้าง (ตอบสลับได้
        ตอบช้าได้ตาม เห็นสมควร)</label
      >
      <div class="p-field p-col-12 p-sm-8">
        <label for="wight"><b>(ต้นไม้ รถยนต์ มือ)</b></label>
      </div>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in buildChoices(4)"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice17']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>
    </div>

    <template #footer>
      <Button
        label="ปิด"
        icon="pi pi-times"
        class="p-button-text"
        @click="() => setModal(false)"
      />
      <Button
        label="ยืนยันผล"
        icon="pi pi-check"
        autofocus
        @click="setLabelValueAndCloseModal"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'modalSumTmse',
  data() {
    return {
      selects: {
        choice1: null,
        choice2: null,
        choice3: null,
        choice4: null,
        choice5: null,
        choice6: null,
        choice7: null,
        choice8: null,
        choice9: null,
        choice10: null,
        choice11: null,
        choice12: null,
        choice13: null,
        choice14: null,
        choice15: null,
        choice16: null,
        choice17: null
      }
    }
  },
  computed: {
    isOpen() {
      return this.$store.getters['prediction/isModalSumTmse']
    },

    assetsNurse: function () {
      return require('@/assets/images/nurse.png')
    },
    assetsHome: function () {
      return require('@/assets/images/home.png')
    }
  },
  methods: {
    buildChoices(size) {
      return Array(size)
        .fill('คะแนน')
        .map((x, idx) => {
          return ` ${idx} ${x} `
        })
    },
    setModal(isOpen) {
      this.$store.commit('prediction/setModal', {
        modalName: 'sum_tmse',
        isOpen
      })
    },
    setLabelValueAndCloseModal() {
      const value = Object.keys(this.selects).reduce((sum, name) => {
        const value = this.selects[name]
        return sum + (value == null ? 0 : value)
      }, 0)
      console.log('value ::==', value)
      this.$store.commit('prediction/setLabelValue', {
        labelName: 'sum_tmse',
        value
      })
      this.setModal(false)
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
  .img-responsive {
    width: 30%;
  }
}
@media only screen and (min-width: 769px) {
  .img-responsive {
    width: 20%;
  }
}
</style>