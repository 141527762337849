<template>
  <Dialog
    v-model:visible="isOpen"
    :closable="false"
    :closeOnEscape="true"
    :style="{ width: '50vw' }"
  >
    <template #header>
      <h3>
        แบบประเมินความเครียด (ST5) (ในระยะ 2-4 สัปดาห์ที่ผ่านมา)
        (ให้ประเมินตนเอง)
      </h3>
    </template>
    <div class="p-field p-col-12 p-sm-12">
      <div class="p-field">
        <label for="choice1">1) มีปัญหาการนอน นอนไม่หลับหรือนอนมาก</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in radios"
            :key="key"
          >
            <RadioButton
              name="marital"
              :value="key"
              v-model="selects['choice1']"
            />
            <label for="choice1">{{ value }}</label>
          </div>
        </div>
      </div>

      <div class="p-field">
        <label for="choice2">2) มีสมาธิน้อยลง</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in radios"
            :key="key"
          >
            <RadioButton
              name="marital"
              :value="key"
              v-model="selects['choice2']"
            />
            <label for="choice2">{{ value }}</label>
          </div>
        </div>
      </div>

      <div class="p-field">
        <label for="choice3">3) หงุดหงิด/กระวนกระวายใจ/ว้าวุ่นใจ </label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in radios"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="selects['choice3']"
              :value="key"
            />
            <label for="choice3">{{ value }}</label>
          </div>
        </div>
      </div>

      <div class="p-field">
        <label for="choice4">4) รู้สึกเบื่อ เซ็ง</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in radios"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="selects['choice4']"
              :value="key"
            />
            <label for="choice4">{{ value }}</label>
          </div>
        </div>
      </div>

      <div class="p-field">
        <label for="choice5">5) ไม่อยากพบปะผู้คน</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in radios"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="selects['choice5']"
              :value="key"
            />
            <label for="choice5">{{ value }}</label>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <Button
        label="ปิด"
        icon="pi pi-times"
        class="p-button-text"
        @click="() => setModal(false)"
      />
      <Button
        label="ยืนยันผล"
        icon="pi pi-check"
        autofocus
        @click="setLabelValueAndCloseModal"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'modalSumSts',
  data() {
    return {
      selects: {
        choice1: null,
        choice2: null,
        choice3: null,
        choice4: null,
        choice5: null
      },
      radios: ['แทบไม่มี', 'เป็นบางครั้ง', 'บ่อยครั้ง', 'เป็นประจำ']
    }
  },
  computed: {
    isOpen() {
      return this.$store.getters['prediction/isModalSumSts']
    }
  },
  methods: {
    setModal(isOpen) {
      this.$store.commit('prediction/setModal', {
        modalName: 'sum_sts',
        isOpen
      })
    },
    setLabelValueAndCloseModal() {
      const value = Object.keys(this.selects).reduce((sum, name) => {
        const value = this.selects[name]
        return sum + (value == null ? 0 : value)
      }, 0)
      console.log('value ::==', value)
      this.$store.commit('prediction/setLabelValue', {
        labelName: 'sum_sts',
        value
      })
      this.setModal(false)
    }
  }
}
</script>

<style>
</style>